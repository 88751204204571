@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

body {
    font-family: 'Montserrat', sans-serif;
}

.container {
    max-width: 1140px;
    padding: 0 15px;
    margin-left: auto;
    margin-right: auto;
}