.count {
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.count__box {
  width: 40px;
  height: 30px;
  background: #EAEFF2;
  border-radius: 3px;
}

.count__input {
  max-width: 100%;
  padding-top: 4px;
  background-color: transparent;

  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
}

.count__input::-webkit-inner-spin-button,
.count__input::-webkit-outer-spin-button {
  appearance: none;
}

.count__controls {
  display: flex;
  flex-direction: column;
  row-gap: 9px;
}

.count__controls img {
  display: block;
}
