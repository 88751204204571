@import "./../App/vars";

.cart-footer {
  display: grid;
  grid-template-columns: 2fr repeat(3, 3fr) 1fr;
  column-gap: 30px;

  padding: 19px 0;
  background: #EAEFF2;

  font-weight: 700;
  font-size: 18px;
  line-height: 1.2;

  @media (max-width: $mobile) {
    padding-left: 15px;
    padding-right: 15px;
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: $mobile-xs) {
    grid-template-columns: 1fr;
    row-gap: 10px;
  }
}

.cart-footer__count {
  grid-column-start: 3;

  @media (max-width: $mobile) {
    grid-column-start: 1;
  }
}