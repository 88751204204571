.section-cart {
    padding: 70px 0 90px;
}

.section-cart__header {
    margin-bottom: 40px;
    text-align: center;
}

.button-wrapper {
    padding: 20px 0;
    text-align: center;
}