.button {
  display: inline-block;
  padding: 10px 15px;

  background-color: #659bbc;
  border-radius: 8px;

  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
}